/* eslint-disable consistent-return */
/*
    This middleware is used to check if the user has permission to access the page.
    It is based on the settings for a campaign.
*/
export default defineNuxtRouteMiddleware((to) => {
    if (/\.\w+$/.test(to.path)) {
        return;
    }

    let cleanPath = to.path;
    const nuxtApp = useNuxtApp();
    const { availableLocales: locales } = nuxtApp.$i18n;

    locales.forEach((locale) => {
        const regex = new RegExp(`^/${locale}(/|$)|(/${locale})(/|$)`, 'g');
        cleanPath = cleanPath.replace(regex, '');
    });

    cleanPath = cleanPath.replace(/^\/|\/$/g, '');

    if (cleanPath === '') {
        const localePath = useLocalePath();
        return navigateTo(localePath('/login'));
    }

    const hasPermission = getPagePermission(cleanPath);
    if (!hasPermission) {
        throw createError({
            statusCode: 404,
            message: 'Page not found',
        });
    }
});
